<template>
	<div>
		<div class="input-wrapper">
			<div class="input-title">Location Name</div>
			<div class="input-box static-text">{{ location.name }}</div>
		</div>
		<div class="input-wrapper">
			<div class="input-title">Location Address</div>
			<div class="input-box static-text">{{ location.address1 }}</div>
		</div>
		<div class="input-wrapper">
			<div class="input-title">Current Status</div>
			<div class="input-box static-text">This location is <b>{{ location.is_active ? 'open for orders' : 'disabled' }}</b>.</div>
		</div>
		<div class="input-wrapper">
			<div class="input-title">Edit Status</div>
			<div class="input-box static-text">
				<a href v-if="location.is_active" @click.prevent="disable">Disable ordering at this location</a>
				<a href v-if="!location.is_active" @click.prevent="enable">Enable ordering at this location</a>
			</div>
		</div>
		<form @submit.prevent="updateLocation">
		<div class="input-wrapper">
			<div class="input-title">Orders Ready Within (Minutes)</div>
			<div class="input-box"><input v-model="form.ready_in_maximum"></div>
		</div>
		<button type="submit" class="submit-standard good-bg">Update Location<span v-if="updatingLocation" class="fal fa-sync fa-spin"></span></button>
		</form>
	</div>
</template>

<script>

export default {

	props: [ 'value' ],

	data() {
		return {
			location: this.value,
			updatingLocation: false,
			form: {
				ready_in_minimum: this.value.ready_in_min,
				ready_in_maximum: this.value.ready_in_max,
			},
		};
	},

	methods: {

		async enable() {
			await this.updateLocationStatus('enable');
		},

		async disable() {
			await this.updateLocationStatus('disable');
		},

		async updateLocationStatus(status) {
			this.updatingLocation = true;

			try {
				await this.$api.post(`/business/locations/${this.location.id}/${ status }`);

				this.location.is_active = status === 'enable';
				this.$emit('input', this.location);
			} catch (e) {
				this.$store.dispatch('errorToast', 'Unable to update location');
			}

			this.updatingLocation = false;
		},

		async updateLocation() {
			this.updatingLocation = true;

			try {
				const { data } = await this.$api.post(`/business/locations/${this.location.id}`, this.form);

				this.location = data.data;
				this.$emit('input', data.data);
			} catch (e) {
				this.$store.dispatch('errorToast', 'Unable to update location');
			}

			this.updatingLocation = false;
		}

	},

};

</script>
