<template>
		<div class="form-wrapper page-has-nav">
			<div class="form-container">
				<div class="input-box">All of the locations associated with your Square account are listed below and enabled by default. You can disable specific locations by using the boxes below. You can also set the approximate time to orders being prepared once received on a per-location basis. <a href @click.prevent="reloadFromProvider" class="neutral-bg">Reload Location(s)</a></div>
			</div>
			<div class="make-item-management-grid">
				<div v-for="location in locations" :key="`location-${ location.id }`" class="form-container">
					<business-location-row :value="location" @input="(newLocation) => location = newLocation"></business-location-row>
				</div>
			</div>
		<loading :full-page="true" v-show="loading"></loading>
		</div>
</template>

<script>

import BusinessLocationRow from './components/BusinessLocationRow';
export default {

	name: 'BusinessLocationsPage',

	data() {
		return {
			loading: true,
			locations: [],
		};
	},

	mounted() {
		this.load();
	},

	methods: {

		async load() {
			await this.handleLoadRequest(this.$api.get('/business/locations'));
		},

		async reloadFromProvider() {
			await this.handleLoadRequest(this.$api.post('/business/locations/reload'));
		},

		async handleLoadRequest(request) {
			this.loading = true;

			try {
				const { data } = await request;

				this.locations = data.data;
			} catch (e) {
				this.$store.dispatch('errorToast', 'Unable to retrieve locations');
			}

			this.loading = false;
		},

	},

	components: {
		BusinessLocationRow,
	},

};

</script>
